<template>
  <div class="form--group">
    <div class="left--col">
      <label>
        <span>{{ $t('addListing.facility') }}</span>
      </label>
    </div>
    <div class="right--col">
      <facility-with-unit-field
        v-for="facility in facilitiesWithUnit"
        :key="facility.id"
        :name="facility.name"
        :checked="facility.checked"
        :id="facility.id"
        :unitName="facility.unitName"
        :amount="facility.amount"
        ref="facilityWithUnitRef"
      />
      <div class="form--group row">
        <facility-without-unit-field
          v-for="facility in facilitiesWithoutUnit"
          :key="facility.id"
          :name="facility.name"
          :checked="facility.checked"
          :id="facility.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
const FacilityWithUnitField = () =>
  import('@/components/listing-form/sect2/facility/facility-with-unit-field.vue');
const FacilityWithoutUnitField = () =>
  import('@/components/listing-form/sect2/facility/facility-without-unit-field.vue');

export default {
  name: 'facilities-section',
  components: {
    FacilityWithUnitField,
    FacilityWithoutUnitField,
    // FacilityWithoutUnit,
  },
  computed: {
    ...mapGetters({
      facilitiesWithUnit: 'v2/listingForm/sect2/detail/facilitiesWithUnit',
      facilitiesWithoutUnit: 'v2/listingForm/sect2/detail/facilitiesWithoutUnit',
    }),
  },
  methods: {
    validate() {
      let validationArray = [];
      const facilityValidation = this.$refs.facilityWithUnitRef.map(function (form) {
        return form.validate();
      });
      validationArray.push(facilityValidation);
      return Promise.all(validationArray).then(function (results) {
        return (
          results.filter(function (result) {
            return !result;
          }).length === 0
        );
      });
    },
  },
};
</script>

<style scoped></style>
